// src/components/VideoShowcase.js

import React from "react";
import "./VideoShowcase.css";

const VideoShowcase = (props) => {
  const videos = props.videos;
  return (
    <section className="video-showcase">
      <h2>Video Showcase</h2>
      <div className="videos">
        {videos.map((video, index) => (
          <div key={index} className="video-card">
            <iframe
              src={video.url}
              title={video.title}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
            <h3>{video.title}</h3>
            <p>{video.description}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default VideoShowcase;
