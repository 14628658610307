import './WhySection.css';

function WhySection() {
    return(
        <div className='why'>
            <div className='que'>Why Choose <br></br>Anemo Studio?</div>
            <div className='ans'>
                We’re a dynamic digital agency that merges your business objectives with our innovative design and technical expertise, bringing your vision to life across websites, mobile applications, and video content. From startups to established brands, we craft digital experiences that captivate and engage your audience.<br></br><br></br>

                Our diverse team of web developers, mobile app creators, video editors, and online marketing specialists works in harmony to deliver projects that resonate. We manage every aspect of your digital needs, ensuring seamless execution and impactful storytelling.<br></br><br></br>

                Consider us your digital dream team, dedicated to enhancing your online presence and driving engagement through cutting-edge technology and creative solutions.
            </div>
        </div>
    );
}

export default WhySection;