import './Footer.css';
import Logo from './images/Logo.png';

function Footer(){
    return(
        <div className='footer'>
            <div className='Fleft'>
                <img className='logo' src={Logo} alt='logo' />
                <span className='email'>contact.anemostudio@gmail.com</span>
            </div>
            <div className='Fright'>
                <span>Pages</span>
                <a href='#HS'>Home</a>
                <a href='#ser'>Services</a>
                <a href='/'>Projects</a>
                <a href='/'>About Us</a>
                <a href='/'>Contact Us</a>
            </div>
        </div>
    );
}

export default Footer;