import './Services.css'
import iconAndroid from './images/icons/iconAndroid.png';
import iconVideo from './images/icons/iconVideo.png';
import iconCode from './images/icons/iconCode.png';
import iconAds from './images/icons/iconAds.png';
import {motion} from 'framer-motion';
import { Link } from 'react-router-dom';

function Services(){
    return(
        <div className='ser' id='se'>
            <span className='services'>Services We Provide</span>
            <motion.div initial={{
                opacity: 0,
            }}
            whileInView={{
                opacity: 1,
            }}
            viewport={{amount: 'all',
                // once: true
            }}
            className='serCont'>
                <div className='ser1'>
                    <img src={iconAndroid} alt='icon'></img>
                    <span>Mobile App <br></br> Development</span>
                    <Link className='lin' to={'/app-service'}></Link>
                </div>
                <div className='ser1'>
                    <img src={iconCode} alt='icon'></img>
                    <span>Website <br></br> Development</span>
                    <Link className='lin' to={'/web-service'}></Link>
                </div>
                <div className='ser1'>
                    <img src={iconVideo} alt='icon'></img>
                    <span>Video Editing & <br></br> Commercial</span>
                    <Link className='lin' to={'/video-editing-service'}></Link>
                </div>
                <div className='ser1'>
                    <img src={iconAds} alt='icon'></img>
                    <span>Online Social Media <br></br> Marketing</span>
                    <Link className='lin' to={'/social-media-marketing'}></Link>
                </div>
            </motion.div>
            
        </div>
    );
}

export default Services;