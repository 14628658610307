import NavBar from "../components/NavBar";
import PricingSec from "../components/PricingSec";
import FAQ from "../components/FAQ";
import ContactUs from "../components/ContactUs";

const plans = [
  {
    title: "Basic",
    price: "8,000",
    features: [
      "- Screens Upto 3",
      "✔️ Firebase",
      "✔️ Android",
      "✔️ Source Code",
      "❌ IOS",
      "❌ Appwrite",
      "❌ Publish you're online (PlayStore and AppStore)",
    ],
    highlighted: false,
    service: "Mobile Application",
  },
  {
    title: "Standard",
    price: "19,000",
    features: [
      "- Screens Upto 7",
      "✔️ Android",
      "✔️ IOS",
      "✔️ Firebase",
      "✔️ Source Code",
      "❌ Appwrite",
      "❌ Publish you're online (PlayStore and AppStore)",
    ],
    highlighted: true,
    service: "Mobile Application",
  },
  {
    title: "Premium",
    price: "30,000",
    features: [
      "- Screens Upto 11",
      "✔️ Android",
      "✔️ IOS",
      "✔️ Firebase",
      "✔️ Appwrite",
      "✔️ Publish you're online (PlayStore and AppStore)",
      "✔️ Source Code",
    ],
    highlighted: false,
    service: "Mobile Application",
  },
];

const qnaData = [
  {
    question:
      "How do you ensure that the mobile app aligns with our business goals and objectives?",
    answer:
      "We start by gaining a deep understanding of your business, target audience, and objectives. We then tailor our development approach to align with your goals, ensuring that the app delivers tangible value and supports your overall business strategy.",
  },
  {
    question: "What is the process for publishing the app to app stores?",
    answer:
      "We handle the entire app publishing process for you. This includes creating developer accounts on relevant app stores, preparing the app for submission, and managing the submission and approval process. Once approved, we oversee the app's deployment to ensure a seamless launch.",
  },
  {
    question: "How do you handle app monetization strategies?",
    answer:
      "We can assist you in implementing various monetization strategies for your app, including in-app purchases, subscriptions, ads, and sponsorships. We'll work with you to determine the best approach based on your app's target audience, content, and business model.",
  },
  {
    question: "Can you provide ongoing analytics and insights for the app?",
    answer:
      "Absolutely! We offer comprehensive analytics and reporting capabilities to track app performance, user engagement, and other key metrics. This data enables us to make informed decisions and optimize the app for continued success.",
  },
  {
    question: "Do you offer post-launch support and maintenance services?",
    answer:
      "Yes, we provide post-launch support and maintenance services to ensure your app remains functional and up-to-date. Our team is available to address any issues, implement updates, and provide technical assistance as needed.",
  },
];
function MobileAppSer() {
  return (
    <div className="MobileAppSerContainer">
      <NavBar high={true} cont={"#ContactUs"} />
      <PricingSec plans={plans} />
      <FAQ FAQData={qnaData} />
      <ContactUs plans={plans} />
    </div>
  );
}

export default MobileAppSer;
