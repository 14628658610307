import './LastPart.css';

function LastPart(){
    return(
        <div className='lastPart'>
            <span className='lastPartText'>Let's Transform Your Digital Vision into Reality.</span>

            <span className='dess'>Outline your objectives and let us take care of everything—from planning to completion. Get in touch, and let's explore how we can support your next digital endeavor.</span>
        </div>
    )
}

export default LastPart;