import React from 'react';
import './WhyUs.css';
import one from './images/icons/one.png'
import customization from './images/icons/customization.png';
import Quality from './images/icons/quality.png';
import Customer from './images/icons/customer-satisfaction.png'

const Reason = ({ icon, title, description }) => {
  return (
    <div className="reason">
      <div className="icon">
        <img className='display-icon' alt='img' src={icon}></img>
      </div>
      <div className="content">
        <h3>{title}</h3>
        <p>{description}</p>
      </div>
    </div>
  );
};

const WhyUs = () => {
    const reasonsData = [
      {
        icon: one,
        title: "Expertise",
        description:
          "Our team of seasoned editors has years of experience in transforming raw footage into cinematic masterpieces. We stay updated with the latest trends and technologies to ensure your content is cutting-edge.",
      },
      {
        icon: customization,
        title: "Customization",
        description:
          "We understand that every project is unique. Our bespoke editing services are tailored to meet your specific needs, ensuring your vision is brought to life exactly as you imagined.",
      },
      {
        icon: Quality,
        title: "Quality",
        description:
          "We are committed to delivering high-quality results. From color grading to sound design, every detail is meticulously handled to ensure your final product is polished and professional.",
      },
      {
        icon: Customer,
        title: "Customer Satisfaction",
        description:
          "Your satisfaction is our priority. We work closely with you throughout the editing process, incorporating your feedback to ensure the final product exceeds your expectations.",
      },
    ];
  return (
    <section id="why-us">
      <div className="container">
        <h2>Why Choose Us?</h2>
        <div className="reasons">
          {reasonsData.map((reason, index) => (
            <Reason 
              key={index} 
              icon={reason.icon} 
              title={reason.title} 
              description={reason.description} 
            />
          ))}
        </div>
      </div>
    </section>
  );
}

export default WhyUs;
