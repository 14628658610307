// src/SocialMediaMarketing.js
import React from "react";
import "./SocialMediaMarketing.css";
import { motion } from "framer-motion";
import video3 from "../components/images/video3.mp4";
import NavBar from "../components/NavBar";
import ContactUs from "../components/ContactUs";

const plans = [
  {
    title: "Social Media Marketing",
    service: "",
    price: "20,000 to 55,000",
  },
];

const services = [
  {
    title: "Content Creation",
    description:
      "High-quality content that resonates with your audience and drives engagement.",
  },
  {
    title: "Social Media Strategy",
    description:
      "Customized strategies to maximize your social media presence and reach.",
  },
  {
    title: "Search Engine Optimization",
    description:
      "Boost your online visibility and drive organic traffic with our expert search engine optimization (SEO) services.",
  },
  {
    title: "Paid Advertising",
    description:
      "Targeted ads to boost your visibility and attract potential customers.",
  },
  {
    title: "Market Research",
    description:
      "Gain valuable insights and make informed decisions with our comprehensive market research services.",
  },
  {
    title: "Public Relations (PR)",
    description:
      "Enhance your brand's reputation and connect with your audience through our strategic public relations (PR) services.",
  },
];

const SocialMediaMarketing = () => {
  return (
    <div>
      <NavBar high={true} cont={"#ContactUs"} />
      <motion.div
        className="smm-container"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <div className="smm-hero-section">
          <video className="videoH" loop autoPlay muted>
            <source src={video3} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <div className="smm-hero-overlay"></div>
          <div className="smm-hero-content">
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.4, duration: 0.5 }}
            >
              <h1 className="smm-hero-title">Social Media Marketing</h1>
              <p className="smm-hero-text">
                Amplify your brand's voice and connect with your audience
                through strategic social media marketing.
              </p>
            </motion.div>
          </div>
        </div>
        <div className="smm-section">
          <motion.h2
            className="smm-subtitle"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.6, duration: 0.5 }}
          >
            Our Services
          </motion.h2>
          <div className="smm-services">
            {services.map((service, index) => (
              <motion.div
                className="smm-service"
                key={index}
                initial={{ opacity: 0, scale: 0.9 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ delay: 0.8 + index * 0.2, duration: 0.5 }}
              >
                <h3 className="smm-service-title">{service.title}</h3>
                <p className="smm-service-description">{service.description}</p>
              </motion.div>
            ))}
          </div>
        </div>
        <hr />
      </motion.div>
      <ContactUs plans={plans} />
    </div>
  );
};

export default SocialMediaMarketing;
