import { useState } from 'react';
import './Contact.css';
import { database } from './firebase';
import { ref, push } from 'firebase/database';

import imgCon from './images/imageContact.png';

export default function Contact() {
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [message, setMessage] = useState('');


    const handleSubmit = (e) => {
        e.preventDefault();
        
        const messagesRef = ref(database, 'messages');
        const newMessage = {
          email,
          name,
          message,
          timestamp: Date.now()
        };

        push(messagesRef, newMessage)
        .then(() => {
          console.log("Data saved successfully!");
          alert("We Will reach you soon!");
          setEmail('');
          setName('');
          setMessage('');
        })
        .catch((error) => {
          console.error("Error saving data: ", error);
          alert("Something Went Wrong!");
        });
    };

    return (
        <div className='contactCon' id='contactUs'>
            <span className='txtContact'>Get in Touch</span>
            <span className='txt2Contact'>Contact us for all your app and website development needs.</span>
            
            <div className='contactContainer'>
              <div className='contactFo'>
              <div className='bg1Contact'></div>
                  <div className='bgContact'></div>
                  <input id='Clname' className='nameCl' type="text" value={name} onChange={(e) => setName(e.target.value)} required placeholder='Name'></input>
                  <input id='Clemail' className='emailCl' type="email" value={email} onChange={(e) => setEmail(e.target.value)} required placeholder='Email'></input>
                  <textarea id='Clmessage' value={message} onChange={(e) => setMessage(e.target.value)}  required className='messageCl' placeholder='Message'></textarea>
                  <a href='/' className='btn' onClick={handleSubmit}>Send</a>
              </div>
              <img className='imageContactS' src={imgCon} alt='contact.anemostudio@gmail.com' />
            </div>
        </div>
    );
}
