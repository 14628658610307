import NavBar from "../components/NavBar";
// import ProjectShowcase from "../components/ProjectShowcase";
import PricingSec from "../components/PricingSec";
import FAQ from "../components/FAQ";
import ContactUs from "../components/ContactUs";

const plans = [
  {
    title: "Basic",
    price: "10,000",
    features: ["- Pages Upto 3", "✔️ Responsive Design", "✔️ Hosting setup", "✔️ Soource Code", " ❌ For Mobile Platform", " ❌ Opt-in Form", "❌ Paayment Processing", "❌ E-Commerce"],
    highlighted: false,
    service: "Website Development",
  },
  {
    title: "Standard",
    price: "18,000",
    features: ["- Pages Upto 3", "✔️ Responsive Design", "✔️ Hosting setup", "✔️ Soource Code", " ✔️ For Mobile Platform", " ✔️ Opt-in Form", "❌ Paayment Processing", "❌ E-Commerce"],
    highlighted: true,
    service: "Website Development",
  },
  {
    title: "Premium",
    price: "26,000",
    features: ["- Pages Upto 3", "✔️ Responsive Design", "✔️ Hosting setup", "✔️ Soource Code", " ✔️ For Mobile Platform", " ✔️ Opt-in Form", "✔️ Paayment Processing", "✔️ E-Commerce"],
    highlighted: false,
    service: "Website Development",
  },
];

const qnaData = [
  {
    question: "What is your approach to website design and development?",
    answer:
      "We take a collaborative approach to website design and development, working closely with our clients to understand their goals, target audience, and brand identity. Our process involves in-depth research, creative brainstorming, iterative design, and meticulous development to deliver a website that not only looks great but also achieves its intended objectives.",
  },
  {
    question:
      "Can you provide examples of websites you've developed in the past?",
    answer:
      "Absolutely! Our portfolio showcases a diverse range of websites we've developed for clients across various industries. Whether it's a simple brochure site, an e-commerce platform, or a complex web application, we have the expertise to bring your vision to life.",
  },
  {
    question:
      "How do you ensure that the website is user-friendly and accessible?",
    answer:
      "User experience (UX) and accessibility are paramount in our design process. We conduct usability testing, optimize for mobile devices, and adhere to web accessibility standards to ensure that all users can navigate and interact with the website easily, regardless of their device or abilities.",
  },
  {
    question:
      "What technologies and platforms do you use for website development?",
    answer:
      "We leverage a wide range of technologies and platforms to develop websites tailored to our clients' needs. This includes content management systems (CMS) like WordPress, e-commerce platforms like Shopify and WooCommerce, as well as front-end frameworks like React and Vue.js for more interactive experiences.",
  },
  {
    question: "How do you handle website maintenance and updates post-launch?",
    answer:
      "We offer ongoing website maintenance and support services to ensure your site remains secure, up-to-date, and performing optimally. From regular software updates and security patches to content updates and feature enhancements, we've got you covered to keep your website running smoothly.",
  },
  {
    question:
      "What is your process for search engine optimization (SEO) and online marketing?",
    answer:
      "We integrate SEO best practices into our website development process from the outset, including keyword research, on-page optimization, and site structure. Additionally, we offer online marketing services such as pay-per-click (PPC) advertising, social media marketing, and content marketing to help drive traffic and improve your website's visibility.",
  },
];

export default function WebsiteSec() {
  return (
    <div className="WebsiteSecContainer">
      <NavBar high={true} cont={"#ContactUs"} />
      <PricingSec plans={plans} />
      <FAQ FAQData={qnaData} />
      <ContactUs plans={plans} />
    </div>
  );
}
