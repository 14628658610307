import { initializeApp } from "firebase/app";
// import { getFirestore } from "firebase/firestore";
import { getDatabase } from "firebase/database";
import key from './key.env';

const firebaseConfig = {
  apiKey: key.apiKey,
  authDomain: key.authDomain,
  databaseURL: "https://anemostudio-e0e09-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "anemostudio-e0e09",
  storageBucket: key.storageBucket,
  messagingSenderId: key.messagingSenderId,
  appId: key.appId,
  measurementId: key.measurementId
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const database = getDatabase(app);