// src/AboutUs.js
import React from 'react';
import './SecAboutUs.css';
import { motion } from 'framer-motion';
import { FaMobileAlt, FaLaptopCode, FaVideo, FaShareAlt } from 'react-icons/fa';

const tiles = [
  {
    title: 'Mobile App Development',
    description: 'Custom mobile applications that engage users and drive business growth.',
    icon: <FaMobileAlt />,
  },
  {
    title: 'Website Creation',
    description: 'Responsive, user-friendly websites to establish a strong online presence.',
    icon: <FaLaptopCode />,
  },
  {
    title: 'Video Editing',
    description: 'Transform raw footage into polished, professional content that tells your story.',
    icon: <FaVideo />,
  },
  {
    title: 'Social Media Marketing',
    description: 'Create and manage social media campaigns to enhance brand visibility.',
    icon: <FaShareAlt />,
  },
];

const AboutUs = () => {
  return (
    <motion.div
      className="container"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <h1 className="title">About Anemo Studio</h1>
      <div className="hero-section">
        <div className="hero-overlay"></div>
        <div className="hero-content">
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.4, duration: 0.5 }}
          >
            <h2 className="hero-title">Who We Are</h2>
            <p className="hero-text">
              Anemo Studio is a full-service agency specializing in mobile app development, website creation, video editing, and social media marketing. Our team of experienced professionals is dedicated to delivering high-quality solutions tailored to meet the unique needs of our clients.
            </p>
          </motion.div>
        </div>
      </div>
      <hr />
      <div className="section">
        <motion.h2
          className="subtitle"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.6, duration: 0.5 }}
        >
          Our Mission
        </motion.h2>
        <motion.p
          className="paragraph"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.8, duration: 0.5 }}
        >
          Our mission is to empower businesses by providing them with the digital tools and strategies they need to succeed in today's competitive market. Whether it's creating a stunning website, developing a robust mobile app, editing captivating videos, or managing a powerful social media campaign, we are here to help.
        </motion.p>
      </div>
      <hr />
      <div className="section">
        <motion.h2
          className="subtitle"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 1, duration: 0.5 }}
        >
          What We Do
        </motion.h2>
        <div className="tiles">
          {tiles.map((tile, index) => (
            <motion.div
              className="tile"
              key={index}
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ delay: 1.2 + index * 0.2, duration: 0.5 }}
            >
              <div className="tile-icon">{tile.icon}</div>
              <h3 className="tile-title">{tile.title}</h3>
              <p className="tile-description">{tile.description}</p>
            </motion.div>
          ))}
        </div>
      </div>
    </motion.div>
  );
};

export default AboutUs;
