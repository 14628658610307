// SandwichButton.js
import React from 'react';
import './SandwichButton.css';

const SandwichButton = ({ isOpen, onClick }) => {
  return (
    <button className={`sandwich-button ${isOpen ? 'open' : ''}`} onClick={onClick}>
      <span className="bar"></span>
      <span className="bar"></span>
      <span className="bar"></span>
    </button>
  );
};

export default SandwichButton;
