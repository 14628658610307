import React from "react";
import NavBar from "../components/NavBar";
import Hero from "../components/Hero";
import WhySection from "../components/WhySection";
import Services from "../components/Services";
import FAQs from "../components/FAQs";
import LastPart from "../components/LastPart";
import Footer from "../components/Footer";
import Contact from "../components/Contact";

import video from "../components/images/video1.mp4";

function Home(params) {
  const faqsData = [
    {
      question: "How can I get started?",
      answer:
        "To get started, simply reach out to our team through our contact form or give us a call. We'll discuss your project requirements and provide you with a tailored solution.",
    },
    {
      question: "What platforms do you develop for?",
      answer:
        "We develop apps and websites for both iOS and Android platforms, ensuring compatibility and optimal user experience across devices.",
    },
    {
      question: "Can you handle video editing?",
      answer:
        "Yes, we have a team of skilled video editors who can enhance your app or website with professional video editing services.",
    },
    {
      question: "What is your pricing?",
      answer:
        "Our pricing is tailored to each project based on its complexity, features, and specific requirements. Contact us for a personalized quote.",
    },
    {
      question: "Do you offer support?",
      answer:
        "Yes, we provide ongoing support and maintenance services to ensure your app or website continues to perform optimally after launch.",
    },
    {
      question: "How can I optimize my app or website for search engines?",
      answer:
        "We offer SEO services to help improve your app or website's visibility in search engine results. Our team will optimize your content, meta tags, and other elements to increase organic traffic.",
    },
  ];
  return (
    <div className="Home">
      <NavBar high={false} cont={'#contactUs'}/>
      <Hero
        video={video}
        lineO={"Grow your"}
        lineT={"Business"}
        lineTh={
          "| With Our Mobile App/Web Development, Video Editing, And Online Marketing Services"
        }
        controls={false}
      />
      <WhySection />
      <Services />
      <FAQs faqs={faqsData} />
      <LastPart />
      <Contact />
      <Footer />
    </div>
  );
}

export default Home;
