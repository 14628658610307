// NavBar.js
import React, { useState, useEffect } from 'react';
import './NavBar.css';
import SandwichButton from './SandwichButton';
import logo from './images/Logo.png';
import { Link } from 'react-router-dom';

function NavBar(props) {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [menuOpen, setMenuOpen] = useState(false);
  const { high, cont } = props;

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      if (window.innerWidth > 768) {
        setMenuOpen(false);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <div className={`nav${high ? 'highlighted' : ''}`}>
      <div className={`navbarBack${high ? 'highlighted' : ''}`}></div>
      <Link to={'/home'} className="BackHome">
        <div className="leftLogo">
          <img className="logO" src={logo} alt="logo" />
          <span className="anemoText">Anemo Studio</span>
        </div>
      </Link>
      {windowWidth <= 768 ? (
        <SandwichButton isOpen={menuOpen} onClick={toggleMenu} />
      ) : (
        <div className="link_sections">
          <div className="dropdown">
            <span className="services-link">Services</span>
            <div className="dropdown-content">
              <Link to="/app-service">App</Link>
              <Link to="/web-service">Website</Link>
              <Link to="/video-editing-service">Video Editing</Link>
              <Link to="/social-media-marketing">Marketing</Link>
            </div>
          </div>
          <a
            href="/"
            onClick={(e) => {
              e.preventDefault();
              alert('Coming Soon');
            }}
          >
            Projects
          </a>
          <Link to="/about-us">About Us</Link>
          <a href={cont}>Contact Us</a>
        </div>
      )}
      {menuOpen && windowWidth <= 768 && (
        <div className="mobile-menu">
          <Link to="/home">Home</Link>
          <Link to="/app-service">App</Link>
          <Link to="/web-service">Website</Link>
          <Link to="/video-editing-service">Video Editing</Link>
          <Link to="/social-media-marketing">Marketing</Link>
          <a
            href="/"
            onClick={(e) => {
              e.preventDefault();
              alert('Coming Soon');
            }}
          >
            Projects
          </a>
          <Link to="/about-us">About Us</Link>
          <a href={cont}>Contact Us</a>
        </div>
      )}
    </div>
  );
}

export default NavBar;
