import React, { useState } from "react";
import "./FAQ.css";
import { motion } from "framer-motion";

const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleFAQ = () => {
    setIsOpen(!isOpen);
  };

  return (
    <motion.div
      initial={{
        opacity: 0,
      }}
      whileInView={{
        opacity: 1,
      }}
      viewport={{
        amount: "all",
        // once: true
      }}
      className={`faq-item ${isOpen ? "open" : ""}`}
    >
      <div className="faq-question" onClick={toggleFAQ}>
        {question}
        <span className="faq-toggle">{isOpen ? "-" : "+"}</span>
      </div>
      {isOpen && <div className="faq-answer">{answer}</div>}
    </motion.div>
  );
};

function FAQ(props) {
  const faqData = props.FAQData;

  return (
    <div className="faq-section">
      <h2>Frequently Asked Questions</h2>
      {faqData.map((faq, index) => (
        <FAQItem key={index} question={faq.question} answer={faq.answer} />
      ))}
    </div>
  );
}

export default FAQ;
