import './Hero.css'

import ingaram from './images/icons/iconInstagram.png';
import facebook from './images/icons/iconX.png';
import linkedin from './images/icons/iconLinkedin.png';

function Hero(props){
    return(
        <div className='HeroSection' id='HS'>
            <video className='videoPlayer' loop autoPlay muted controls={props.controls}>
                <source src={props.video} type='video/mp4' />
            </video>
            {/* <div className='grayd'></div> */}
            <span className='gro'>{props.lineO}</span>
            <span className='gr'>{props.lineT}</span>
            <span className='ourSer'>{props.lineTh}</span>
            <div className='iconSocial'>
                <a href='https://www.instagram.com/anemo_studio_?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==' target='_blank' rel='noreferrer'><img src={ingaram} alt='icon'></img></a>
                <a href='https://x.com/Anemo_Studio'><img src={facebook} alt='icon' target='_blank' rel='noreferrer'></img></a>
                <a href='https://www.linkedin.com/in/anemo-studios-44735b310/' target='_blank' rel='noreferrer'><img src={linkedin} alt='icon'></img></a>
            </div>
        </div>
    );
}

export default Hero;