import './FAQs.css';
import React, { useState } from 'react';
import { motion } from 'framer-motion';

// FAQItem component
function FAQItem({ question, answer }) {
    const [isOpen, setIsOpen] = useState(false);

    const toggleAccordion = () => {
        setIsOpen(!isOpen);
    };

    return (
        <motion.div initial={{
            opacity: 0,
        }}
        whileInView={{
            opacity: 1,
        }}
        viewport={{amount: 'all',
            // once: true
        }} className={`sameQ ${isOpen ? 'open' : ''}`} onClick={toggleAccordion}>
            <span className='question'>{question}</span>
            {isOpen && <span className='answer'>{answer}</span>}
        </motion.div>
    );
}

// FAQs component
function FAQs({ faqs }) {
    return (
        <div className='FAQs'>
            <span className='title'>FAQs</span>
            <div className='questions'>
                <div className='left'>
                    {faqs.slice(0, Math.ceil(faqs.length / 2)).map((faq, index) => (
                        <FAQItem key={index} question={faq.question} answer={faq.answer} />
                    ))}
                </div>
                <div className='right'>
                    {faqs.slice(Math.ceil(faqs.length / 2)).map((faq, index) => (
                        <FAQItem key={index} question={faq.question} answer={faq.answer} />
                    ))}
                </div>
            </div>
            <div className='endContent'>
                <span className='still'>Still have questions?</span>
                <a href='#contactUs' className='contact'>Contact us</a>
            </div>
        </div>
    );
}

export default FAQs;
