import ContactUs from "../components/ContactUs";
import PricingSec from "../components/PricingSec";
import VideoShowcase from "../components/VideoShowcase";
import NavBar from "../components/NavBar";
import WhyUs from "../components/WhyUs";
import Hero from "../components/Hero";
import video from "../components/images/video2.mp4";

const videos = [
  {
    title: "Video Editing Techniques",
    description:
      "Unlock the potential of your videos with our mastery of advanced editing techniques.",
    url: "https://www.youtube-nocookie.com/embed/RV4aUuun6Fg?si=qODW6q83FpR891wY&amp",
  },
  {
    title: "Commercial Video Production",
    description:
      "Craft compelling stories and elevate your brand with our commercial video production services.",
    url: "https://www.youtube-nocookie.com/embed/NvmqUsfnn3o?si=91jsMN0RqR_knd12",
  },
];

const plans = [
  {
    title: "Basic",
    price: "1,999",
    features: [
      "- 2 layer (Video timeline)",
      "- Cut/trim",
      "- Color Grading (Basic)",
      "- Sound design & mixing (Basic)",
      "- Transition and effects (limited/Basics)",
    ],
    highlighted: false,
    service: "Video Editing",
  },
  {
    title: "Standard",
    price: "6,999",
    features: [
      "- 4 layer (Video timeline)",
      "- Cut/trim",
      "- Color Grading (Moderate)",
      "- Sound design & mixing (Moderate)",
      "- Transition and effects (limited)",
      "- Stock Images/Videos (free)",
      "- Source File",
    ],
    highlighted: true,
    service: "Video Editing",
  },
  {
    title: "Premium",
    price: "10,999",
    features: [
      "- unlimited layers (Video timeline)",
      "- Cut/trim",
      "- Color Grading (Advance)",
      "- Sound Design & Mixing (Advance)",
      "- Transition & Effects (Unlimited/Advance)",
      "- Stock Images/Videos (free)",
      "- Source File",
      ],
    highlighted: false,
    service: "Video Editing",
  },
];

export const VideoEditing = () => {
  return (
    <div className="video-editing-contaner">
      <NavBar />
      <Hero video={video} />
      <VideoShowcase videos={videos} />
      <WhyUs />
      <PricingSec plans={plans} />
      <ContactUs plans={plans} />
    </div>
  );
};
