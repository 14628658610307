import AboutUs from "../components/SecAboutUs";

import NavBar from "../components/NavBar";

export default function About() {
  return (
    <div>
      <NavBar high={true} cont={""}/>
      <AboutUs />
    </div>
  );

}