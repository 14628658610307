import "./PricingSec.css";


const PricingCard = ({ title, price, features, highlighted, service }) => (
  <div className={`pricing-card ${highlighted ? 'highlighted' : ''}`}>
    <h3>{title}</h3>
    <p className="price">Rs. {price}<span></span></p>
    <hr></hr>
    <ul>
      {features.map((feature, index) => (
        <li key={index}>{feature}</li>
      ))}
    </ul>
    <button onClick={
      () => {
        window.scrollTo({
          top: document.getElementById("ContactUs").offsetTop,
          behavior: "smooth",
        });
      }
    }>Choose Plan</button>
    <p>{service}</p>
  </div>
);


function PricingSec({plans}) {
  return (
    <div className="pricing-section">
    <h2>Our Pricing Plans</h2>
    <div className="pricing-cards">
      {plans.map((plan, index) => (
        <PricingCard
          key={index}
          title={plan.title}
          price={plan.price}
          features={plan.features}
          highlighted={plan.highlighted}
          service={plan.service}
        />
      ))}
    </div>
    <div className="more-details">For Customized Plan Contact Us</div>
    <div className="spacer"></div>
    <a href="#ContactUs" className="contact">Contact Us</a>
  </div>
  );
}

export default PricingSec;
