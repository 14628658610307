import React from 'react';
import Home from './pages/Home';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import MobileAppSer from './pages/MobileAppSer';
import WebsiteSec from './pages/WebsiteSec';
import { VideoEditing } from './pages/VideoEditingSer';
import About from './pages/AboutUs';
import SocialMediaMarketing from './pages/SocialMediaMarketing';

import { useEffect } from 'react';


function App() {
  useEffect(() => {
    // Disable horizontal scrolling
    const preventHorizontalScroll = () => {
      document.body.style.overflowX = 'hidden';
      document.documentElement.style.overflowX = 'hidden';
    };

    preventHorizontalScroll();

    // Cleanup function to reset the overflow property
    return () => {
      document.body.style.overflowX = '';
      document.documentElement.style.overflowX = '';
    };
  }, []);

  return(
    <div>
      <BrowserRouter>
        <Routes>
          <Route index element={<Home />}></Route>
          <Route path='/home' element={<Home />}></Route>
          <Route path='/app-service' element={<MobileAppSer />}></Route>
          <Route path='/web-service' element={<WebsiteSec />}></Route>
          <Route path='/video-editing-service' element={<VideoEditing />}></Route>
          <Route path='/social-media-marketing' element={<SocialMediaMarketing />}></Route>
          <Route path='/about-us' element={<About />}></Route>
          <Route path='*' element={<Home />}></Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
