// src/ContactUs.js
import React, { useState } from 'react';
import { ref, push } from 'firebase/database'; // Ensure these imports are correct based on your firebase setup
import { database } from './firebase'; // Adjust the path based on your project structure
import './ContactUs.css';

function ContactUs({ plans }) {
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [message, setMessage] = useState('');
  const [selectedPlan, setSelectedPlan] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    const messagesRef = ref(database, 'Orders');
    const newMessage = {
      email,
      name,
      message,
      selectedPlan,
      timestamp: Date.now(),
    };

    push(messagesRef, newMessage)
      .then(() => {
        console.log("Data saved successfully!");
        alert("We will reach you soon!");
        setEmail('');
        setName('');
        setMessage('');
        setSelectedPlan('');
      })
      .catch((error) => {
        console.error("Error saving data: ", error);
        alert("Something went wrong!");
      });
  };

  return (
    <div className="contact-us-section" id="ContactUs">
      <h2>Contact Us</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="name">Name:</label>
          <input
            type="text"
            id="name"
            name="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="email">Email:</label>
          <input
            type="email"
            id="email"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="message">Message:</label>
          <textarea
            id="message"
            name="message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="plan">Select Plan:</label>
          <select
            id="plan"
            name="selectedPlan"
            value={selectedPlan}
            onChange={(e) => setSelectedPlan(e.target.value)}
            required
          >
            <option value="">Choose a plan</option>
            {plans.map((plan, index) => (
              <option key={index} value={plan.title}>
              {plan.service} {plan.title} - Rs.{plan.price}
              </option> //months or anything
            ))}
          </select>
        </div>
        <button type="submit">Submit</button>
      </form>
    </div>
  );
}

export default ContactUs;
